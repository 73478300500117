<template>
  <div style="min-height: 85vh; overflow: hidden" @click="clearThings()">
    <div class="el-icon-s-home" style="margin: 15px 20px; font-size: 22px; cursor: pointer">
      <span v-for="(r, index) in route" :key="index" style="font-size: 18px">
        <span @click="handleClick(r.id, null, null, true, 0)">>{{r.name}}</span>
      </span>
    </div>
    <div v-if="editAuth" style="margin: 15px 20px; float: right">
      <div
        class="el-icon-user-solid"
        style="float: left; margin-right: 30px; font-size: 22px; cursor: pointer"
        @click="$refs.panRoleEdit.open()">
      </div>
      <pan-role-edit ref="panRoleEdit" @save-success="getData()"></pan-role-edit>
      <div style="float: left; margin-right: 30px; font-size: 22px; cursor: pointer">
        <vm-upload-file
          v-model="model.filePath"
          :filename.sync="model.name"
          accept=".pdf"
          @file="handleFile">
        </vm-upload-file>
      </div>
      <div
        class="el-icon-plus"
        style="float: left; margin-right: 30px; font-size: 22px; cursor: pointer"
        @click="handleAdd()">
        新建文件夹
      </div>
    </div>
    <!--    <hr style="border: 1px dashed #000"></hr>-->
    <div style="clear: both"></div>
    <div
      v-for="item in data"
      :key="item.id"
      v-popover:item.id
      style="
        margin: 20px 0px 40px 40px;
        width: 200px;
        height: 120px;
        float: left;
        text-align: center;
        cursor: pointer;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      "
      @dblclick="handleClick(item.id, item.name, item.type, false, null)"
      @contextmenu.prevent="handleRightClick(item.id)">
      <div
        v-if="item.type === 16"
        class="el-icon-folder-opened"
        style="font-size: 80px; color: #2d8cf0; margin: 0 auto"></div>
      <div v-else class="el-icon-document" style="font-size: 80px; color: #000; margin: 0 auto"></div>
      <div
        v-if="renameVisible && item.id === rightClickId"
        style="width: 100px; font-size: 14px; margin: 0 auto"
        @click.stop="1">
        <el-input v-model="item.name" size="mini" @blur="doRename(item.id, item.name)"></el-input>
      </div>
      <div v-else style="font-size: 14px; margin: 0 auto">{{item.name}}</div>
      <el-popover
        :ref="item.id"
        :value="rightClickVisible && item.id === rightClickId"
        placement="bottom"
        width="150"
        trigger="manual">
        <div v-if="editAuth" style="width: 100%; text-align: center">
          <div v-if="item.type === 128">
            <el-popover placement="right" trigger="hover">
              <vue-qr
                :size="180"
                :margin="5"
                :text="item.filePath"
                logo-src="/img/step_cc.png"
                :logo-scale="1.5">
              </vue-qr>
              <el-button
                slot="reference"
                style="margin: 5px auto"
                type="text"
                @click.stop="downLoad(item.filePath)">
                <span style="font-size: 16px">下载</span>
              </el-button>
            </el-popover>
          </div>
          <div v-if="item.type === 128 && item.name.indexOf('.pdf') > 0">
            <el-popover placement="right" trigger="hover">
              <vue-qr
                :size="180"
                :margin="5"
                :text="getReadUrl(item.filePath)"
                :logo-src="tenant.smallLogo"
                :logo-scale="0.25">
              </vue-qr>
              <el-button
                slot="reference"
                style="margin: 5px auto"
                type="text"
                @click.stop="read(item.filePath)">
                <span style="font-size: 16px">阅读</span>
              </el-button>
            </el-popover>
          </div>
          <div>
            <el-button
              type="text"
              style="margin: 5px auto"
              @click.stop="handleRename(item.id)">
              <span style="font-size: 16px">重命名</span>
            </el-button>
          </div>
          <div>
            <el-upload
              :action="uploadBaseUrl + 'pans/replaceFile/' + item.id"
              :before-upload="beforeUpload"
              :on-error="handlerError"
              :on-success="handleSuccess"
              :multiple="false"
              :show-file-list="false"
              accept=".*"
              style="display: inline-block">
              <el-button type="text" style="margin: 5px auto">
                <span v-if="item.type" style="font-size: 16px">替换</span>
              </el-button>
            </el-upload>
          </div>
          <div>
            <el-button
              type="text"
              style="margin: 5px auto">
              <span style="font-size: 16px" @click.stop="handleDelete(item.id)">删除</span>
            </el-button>
          </div>
        </div>
      </el-popover>
    </div>
  </div>
</template>
<script>
  import vueQr from "vue-qr";
  import VmUploadFile from "@/components/VmUploadFile";
  import PanRoleEdit from "./PanRoleEdit";
  import tenant from "@/util/tenant";

  export default {
    name: "Pan",
    components: {vueQr, VmUploadFile,PanRoleEdit},
    data() {
      return {
        uploadBaseUrl: window.config.basicServerUrl,
        editAuth: this.$auth(831),
        uploadPercent: "",
        uploadLoading: false,
        model: {
          id: "",
          parentId: "",
          type: "",
          name: "",
          filePath: "",
          file: {},
        },
        route: [{name: "$:我的资料", id: null}],
        parentId: 0,
        data: [],
        rightClickVisible: false,
        rightClickId: 0,
        renameVisible: false,
        renameId: 0,
        tenant: tenant.get(),
      };
    },
    mounted() {
      this.getData();
    },
    methods: {
      getData() {
        this.$http.get(`pans/parentId/${this.parentId}`).then((resp) => {
          console.log(resp.data);
          this.data = resp.data;
        });
      },
      handleFile(file) {
        console.log(file);
        this.model.file = file;
        console.log(this.model);
        this.model.parentId = this.parentId;
        this.model.type = 128;
        this.insertFile(this.model);
      },
      insertFile(model) {
        this.$http.post("pans/insertFile", model).then(() => {
          this.getData();
          this.$message.success("上传"+model.name+"成功");
          model.name = "";
        }).catch(error => {
          this.$message.error(this.$t("common.tip.saveError") + "," + error.response.data.message);
        });
      },
      handleClick(id, name, type, isRoute, index) {
        if (this.rightClickVisible) {
          this.rightClickVisible = !this.rightClickVisible;
        }
        if (type == 16 || isRoute) {
          if (!isRoute) {
            this.route.push({name: name, id: id});
          } else {
            this.route.splice(index + 1, this.route.length);
          }
          this.parentId = id == null ? 0 : id;
          this.getData();
        }
      },
      handleRightClick(id) {
        this.rightClickVisible = !this.rightClickVisible;
        this.rightClickId = id;
        this.renameVisible = false;
      },
      handleRename(id) {
        this.rightClickVisible = false;
        this.renameVisible = true;
        this.renameId = id;
      },
      clearThings() {
        this.rightClickVisible ? (this.rightClickVisible = false) : this.rightClickVisible;
        this.renameVisible ? (this.renameVisible = false) : this.renameVisible;
      },
      doRename(id, name) {
        let params = {
          id: id,
          name: name,
        };
        this.$http
          .save("pans", params)
          .then(() => {
          })
          .catch(() => {
            this.$message.error("重命名失败");
          });
      },
      handleAdd() {
        let params = {
          parentId: this.parentId,
          type: 16,
          name: "新建文件夹",
        };
        this.$http.save("pans/insertFolder", params).then(() => {
          this.getData();
        });
      },
      handleDelete(id) {
        this.$confirm("确定删除吗?", "提示", {type: "warning"}).then(() => {
          this.$http.delete("pans", id).then(() => {
            this.getData();
          });
        });
      },
      getDownLoadUrl(id) {
        let url = window.config.basicServerUrl + "pans/downLoadFile?id=" + id;
        console.log(url);
        return url;
      },
      downLoad(filePath) {
        let url = filePath;
        let a = document.createElement("a");
        a.href = url;
        a.click();
      },
      getReadUrl(filePath) {
        let url = "http://" + window.location.host + "/#/pdf?url=" + filePath;
        console.log(url);
        return url;
      },
      read(filePath) {
        window.open("#/pdf?url=" + filePath);
      },
      onProgress(event) {
        this.uploadLoading = true;
        this.uploadPercent = Math.round(event.percent) + "%";
      },
      beforeUpload(file) {
        let size = file.size / 1024 / 1024;
        if (size > 300) {
          this.$message.error("文件不能超过 300MB!");
          return false; // 返回false中止上传
        }
      },
      handleSuccess() {
        this.$message.success("上传成功");
        this.getData();
        this.uploadLoading = false;
        this.uploadPercent = "";
      },
      handlerError(err) {
        this.$message.error(err.message);
        this.uploadLoading = false;
        this.uploadPercent = "";
      },
    },
  };
</script>
<style lang="scss" scoped></style>
